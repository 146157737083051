import { Spinner } from 'react-bootstrap';

import styles from 'css/src/exports.module.scss';
import 'css/src/components/common/Toast';

import type { ReactNode } from 'react';
import React from 'react';
import type { ToastOptions } from 'react-hot-toast';
import toast, { Toaster } from 'react-hot-toast';

import { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS, IconButton } from 'js/src/components/common/ui/Button';
import uuid from 'js/src/libs/uuid';

enum ToastType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
}

export class Toast {
    /**
     * NOTE: if you call a toast method before the ToastComponent has been rendered (ie. in `componentDidMount` or
     *       in a component constructor) then the toast will not be displayed. This is because the ToastComponent
     *       must be rendered in the DOM before it can display toasts.
     */
    static Component = React.memo(function ToastComponent() {
        // This component should only be rendered 1x on the entire page.
        // Then we can call Toast.success, Toast.error, etc. to show toasts.
        return (
            <Toaster containerClassName="lumen5-toast" />
        );
    });

    static removeAll() {
        toast.dismiss();
    }

    static success(msg: ReactNode, title?: ReactNode, id?: string, duration?: number) {
        const opts = {
            ...this.#getOptions(ToastType.SUCCESS, id, duration),
            icon: (
                <span className="material-symbols-outlined toast-status-icon success">check_circle</span>
            ),
        };
        toast(this.#createContent(msg, title, opts), opts);
        return opts.id;
    }

    static error(msg: ReactNode, title?: ReactNode, id?: string, duration?: number) {
        const opts = {
            ...this.#getOptions(ToastType.ERROR, id, duration),
            icon: (
                <span className="material-symbols-outlined toast-status-icon error">error</span>
            ),
        };
        toast(this.#createContent(msg, title, opts), opts);
        return opts.id;
    }

    static info(msg: ReactNode, title?: ReactNode, id?: string, duration?: number) {
        const opts = {
            ...this.#getOptions(ToastType.INFO, id, duration),
            icon: (
                <span className="material-symbols-outlined toast-status-icon info">info</span>
            ),
        };
        toast(
            this.#createContent(msg, title, opts),
            opts
        );
        return opts.id;
    }

    static loading(msg: ReactNode, title?: ReactNode, id?: string, duration?: number) {
        const opts = {
            ...this.#getOptions(ToastType.INFO, id, duration),
            icon: (
                <Spinner className="toast-status-icon loading" size="sm" />
            ),
        };
        toast(
            this.#createContent(msg, title, opts),
            opts
        );
        return opts.id;
    }

    static #createContent(msg: ReactNode, title: ReactNode, opts: ToastOptions) {
        return (
            <>
                <div className="d-flex flex-column">
                    { title && <strong>{title}</strong> }
                    {msg}
                </div>
                <IconButton
                    size={BUTTON_SIZES.SM}
                    variant={BUTTON_VARIANTS.LINK}
                    color={BUTTON_COLORS.SIMPLE}
                    onClick={() => {
                        toast.dismiss(opts.id);
                    }}
                    icon="close"
                    className="closer-button position-absolute top-0 end-0 me-l5-16"
                />
            </>
        );
    }

    static #getOptions(toastType: ToastType, id?: string, duration?: number): ToastOptions {
        return {
            style: {
                alignItems: 'flex-start',
                maxWidth: '700px',
                padding: '16px',
                margin: '0px',
                backgroundColor: toastType === ToastType.ERROR ? styles['allColors-color-error-100'] : undefined,
            },
            id: id || uuid(),
            duration: duration || 3000,
        };
    }
}
