import ReactBootstrapSpinner from 'react-bootstrap/Spinner';

import React from 'react';

type Props = {
    size?: 'sm',
    className?: string,
}

/**
 * An Icon spinner.
 */
function Spinner({
    size = undefined,
    className = 'text-reset',
}: Props) {
    return (
        <ReactBootstrapSpinner
            animation="border"
            size={size}
            as="i"
            className={`spinner ${className}`}
            data-testid="spinner"
        />
    );
}

const MemoizedSpinner = React.memo(Spinner);
export default MemoizedSpinner;
export { Spinner };
