import debounce from 'lodash/debounce';

import { ApiService, Urls } from 'js/src/client';
import { ErrorReporter } from 'js/src/libs/error-reporter';
import Navigation from 'js/src/libs/navigation';
import { isApiError } from 'js/src/types';


const MAX_32_SIGNED_INTEGER = 2147483647;
const MIN_TIMEOUT_INTERVAL = 1000 * 60; // 1 minute

export class SessionExpiryTimer {
    /**
     * This class is required for certain enterprise requirements, specifically when they have
     * "custom_session_timeout_minutes" configured and want to automatically log out users after a given time.
     *
     * This class pings a simple endpoint every interval where the user has been inactive to see if the session
     * has expired. If it has, it will redirect the user to the login page.
     */
    #timeoutMillis: number;
    #timeout: number | null = null;
    #debounceTime: number;
    #debouncedInteractionListener: () => void;
    #EVENTS = [
        'mouseover',
        'scroll',
        'keydown',
    ];

    constructor(timeoutMinutes: number) {
        if (!timeoutMinutes) {
            throw new Error('SessionExpiryTimer requires a timeout in minutes');
        }

        this.#timeoutMillis = timeoutMinutes * 60 * 1000;
        this.#debounceTime = this.#timeoutMillis / 100;

        this.#debouncedInteractionListener = debounce(() => {
            this.#interactionListener();
        }, this.#debounceTime);
        this.#EVENTS.forEach((eventName) => {
            document.body.addEventListener(eventName, this.#debouncedInteractionListener);
        });

        this.#resetTimeout();
    }

    destroy() {
        this.#EVENTS.forEach((eventName) => {
            document.body.removeEventListener(eventName, this.#debouncedInteractionListener);
        });
        if (this.#timeout) {
            window.clearTimeout(this.#timeout);
            this.#timeout = null;
        }
    }

    #resetTimeout() {
        if (this.#timeout) {
            window.clearTimeout(this.#timeout);
            this.#timeout = null;
        }

        // setTimeout delays cannot be greater than 32-bit signed integer
        // if that happens, it's recommended to just ignore the timeout
        // https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
        if (this.#timeoutMillis < MAX_32_SIGNED_INTEGER) {
            // in case this.#timeoutMillis is 0, we should at the very least ping every minute, no more.
            const timeoutWithMin = this.#timeoutMillis < MIN_TIMEOUT_INTERVAL ? MIN_TIMEOUT_INTERVAL : this.#timeoutMillis;
            this.#timeout = window.setTimeout(() => {
                this.checkAuthStatus();
            }, timeoutWithMin);
        }
    }

    checkAuthStatus() {
        ApiService.isAuthenticatedRetrieve()
            .then(() => {
                // we are logged in still. Reset the timer
                this.#resetTimeout();
            })
            .catch((e: unknown) => {
                if (isApiError(e) && e.status === 403) {
                    analytics.track('Session Expired, Redirecting to Login Page');
                    Navigation.goToLocation(Urls.LOGIN);
                }
                else {
                    ErrorReporter.capture(e);
                    this.#resetTimeout();
                }
            });
    }

    #interactionListener() {
        this.#resetTimeout();
    }
}

// just immediately start the timer if this file is imported!
// if the user doesn't have a custom session timeout, we don't need to do anything
if (SERVER?.USER?.custom_session_timeout_minutes) {
    new SessionExpiryTimer(SERVER.USER.custom_session_timeout_minutes);
}
