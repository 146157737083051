// Used a wrapper so these methods can easily be stubbed with jest.fn() during testing
class Navigation {
    static goToLocation(location: string) {
        window.location.href = location;
    }

    static openNewTab(url: string) {
        window.open(url, '_blank');
    }
}

export default Navigation;
