import { Tooltip as BootstrapTooltip } from 'bootstrap';

import 'css/src/components/common/ui/Tooltip';

import React from 'react';
import Immutable from 'immutable';
import classNames from 'classnames';

import { isTesting } from 'js/src/libs/custom-utils';

export enum Size {
    LARGE = 'large',
}

export enum Placement {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
}
export const NO_FALLBACKS = Immutable.List<Placement>();
export const NORMAL_FALLBACKS = Immutable.List<Placement>([Placement.TOP, Placement.BOTTOM, Placement.LEFT, Placement.RIGHT]);
type Props = {
    onlyShowOnTruncate?: boolean, // Used with a child component that has text-overflow: ellipsis, to only show the tooltip if the text is truncated
    children?: React.ReactElement,
    title?: string,
    animation?: boolean,
    placement?: Placement,
    trigger?: string,
    container?: string | Element | null,
    html?: boolean,
    template?: string,
    viewport?: string | Element | null,
    delay?: number | { show: number, hide: number },
    show?: boolean,
    className?: string,
    anchorElm?: Element | null,
    fallbackPlacements?: Immutable.List<Placement>,
    size?: Size,
};

const isEllipsisActive = (e: Element) => {
    if (e instanceof HTMLElement) {
        return e.offsetWidth < e.scrollWidth;
    }
    return false;
};

/*
 * This is a wrapper of the Boostrap tooltip which handles init and teardown
 * according to the React lifecycle.
 *
 * Usage: wrap at least one child <Tooltip>{node}</Tooltip>. This node should not
 * change during the lifecycle of the tooltip.
 *
 * This component supports a subset of the options described in
 * https://getbootstrap.com/docs/4.0/components/tooltips/#options.
 *
 * NOTE: the `show` prop is unique to this component and controls whether or not a tooltip is actually
 * initialized. If set to false, no tooltip will be attached to this component's children. This prop, along
 * with all the other props, are controlled.
 */
class Tooltip extends React.PureComponent<Props> {
    static defaultProps = {
        animation: true,
        placement: 'auto',
        trigger: 'hover focus',
        container: 'body',
        html: false,
        delay: 300,
        show: true,
    };
    _isMounted: boolean;
    _childRef: React.RefObject<Element>;

    constructor(props: Props) {
        super(props);
        this._isMounted = false;
        this._childRef = React.createRef();
    }

    override componentDidMount() {
        this._isMounted = true;
        if (this.props.show) {
            this.initTooltip();
        }
    }

    override componentDidUpdate(prevProps: Props) {
        if (prevProps.show && !this.props.show) {
            this.destroyTooltip();
        }
        else if (!prevProps.show && this.props.show) {
            this.initTooltip();
        }
        else {
            const doRecreate = prevProps.title !== this.props.title
                || prevProps.animation !== this.props.animation
                || prevProps.placement !== this.props.placement
                || prevProps.trigger !== this.props.trigger
                || prevProps.container !== this.props.container
                || prevProps.html !== this.props.html
                || prevProps.template !== this.props.template
                || prevProps.delay !== this.props.delay
                || prevProps.anchorElm !== this.props.anchorElm;
            if (doRecreate) {
                this.recreateTooltip();
            }
        }
    }

    override componentWillUnmount() {
        this.destroyTooltip();
        this._isMounted = false;
    }

    getAnchorElement(): Element | null {
        if (this.props.anchorElm) {
            return this.props.anchorElm;
        }
        return this._childRef.current;
    }

    getTooltipInstance() {
        const anchor = this.getAnchorElement();
        if (anchor) {
            return BootstrapTooltip.getInstance(anchor);
        }
        return null;
    }

    destroyTooltip() {
        const instance = this.getTooltipInstance();
        if (instance) {
            instance.dispose();
        }
    }

    recreateTooltip() {
        if (!this._isMounted) {
            return;
        }
        this.destroyTooltip();
        this.initTooltip();
    }

    initTooltip() {
        if (!this._isMounted) {
            return;
        }

        const opts: Record<any, any> = {
            title: this.props.title,
            animation: this.props.animation,
            placement: this.props.placement,
            trigger: this.props.trigger,
            container: this.props.container,
            html: this.props.html,
            delay: this.props.delay,
            fallbackPlacements: this.props.fallbackPlacements ? this.props.fallbackPlacements.toJS() : NORMAL_FALLBACKS.toJS(),
        };
        if (this.props.viewport) {
            opts['viewport'] = this.props.viewport;
        }
        if (this.props.template) {
            opts['template'] = this.props.template;
        }
        opts['customClass'] = classNames(this.props.className, this.props.size === Size.LARGE ? 'tooltip--lg' : '');

        if (isTesting()) {
            // turn off animations and delay for testing, as it causes flakiness
            opts.animation = false;
            opts.delay = 0;
        }

        const anchorElement = this.getAnchorElement();
        if (!anchorElement) {
            return;
        }
        try {
            new BootstrapTooltip(anchorElement, opts);
            const tooltipInstance = BootstrapTooltip.getInstance(anchorElement);
            if (!tooltipInstance) {
                return;
            }
            if (this.props.trigger === 'manual') {
                if (this.props.show) {
                    tooltipInstance.show();
                }
                else {
                    tooltipInstance.hide();
                }
            }
            if (this.props.onlyShowOnTruncate && !isEllipsisActive(anchorElement)) {
                tooltipInstance.disable();
            }
        }
        catch (err) {
            // do nothing
        }
    }

    assertChildren() {
        if (!this.props.children) {
            return;
        }
        const children = React.Children.toArray(this.props.children);
        if (children.length === 0) {
            return;
        }
        const firstChild = children[0];
        if (!React.isValidElement(firstChild)) {
            return; // it is a primitive (ex. string, number, null)
        }
        if (!(firstChild as any).ref) {
            return;
        }
        throw new Error('First child of the tooltip must not have a ref. Wrap the children in a div or other element. Or move the ref from the child to a parent outside the tooltip. Or use the anchorElm prop as done in https://github.com/Lumen5/web/pull/13992/files.');
    }

    override render() {
        if (this.props.children) {
            this.assertChildren();
            return React.cloneElement(this.props.children, { ref: this._childRef });
        }
        return null;
    }
}

export default Tooltip;
