import { client } from 'js/src/client';
import { ApiError, isUndefined } from 'js/src/libs/consts';
import Cookies from 'js-cookie';

function getCSRFToken(): string {
    const csrfToken = Cookies.get('csrftoken');
    return isUndefined(csrfToken) ? '' : csrfToken;
}

function getWorkspaceId(): string {
    return isUndefined(SERVER?.USER?.workspace_id) ? '' : `${SERVER.USER.workspace_id}`;
}

client.interceptors.request.use((request) => {
    request.headers.set('Content-Type', 'application/json');

    // add the csrf token to the headers in all requests, so that django's
    // csrf protection will not reject requests sent via the OpenAPI client
    request.headers.set('X-CSRFToken', getCSRFToken());

    // add the Lumen5 workspace id to the headers in all requests, so that
    // the backend can determine which workspace the request is for
    request.headers.set('X-LUMEN5-WORKSPACE', getWorkspaceId());

    return request;
});

client.interceptors.error.use((error, response, request, opts) => {
    if (!(error instanceof Error)) {
        return new ApiError(response.status, error, opts.url);
    }
    return error;
});

client.setConfig({
    throwOnError: true,
    fetch: fetch || window.fetch,
});
